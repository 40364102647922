import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css'
import './locales/index';
import { Login } from './components/Login/Login';
import { Signals } from './components/Signals/Signals';
import * as AnalyticsService from './services/AnalyticsService';
import './App.scss';
import { Container } from 'semantic-ui-react';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import { Subscribed } from './components/Subscribed/Subscribed';

function App() {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    setAuthenticated(true);
  }, [])

  if (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1") {
    AnalyticsService.initialise();
    AnalyticsService.pageView(window.location.pathname + window.location.search)
  } else {
    console.log("We're local baby!")
  }

  return (
    < Container className="App" >
      <Router>
        <div>
          <ServiceWorkerWrapper />
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/subscribed">
              <Subscribed />
            </Route>
            <Route path="/">
              <Signals />
            </Route>
          </Switch>
        </div>
      </Router>
    </ Container>
  );
}

export default App;



