import React from 'react'
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/pt';
import 'moment/locale/it';
import 'moment/locale/en-gb';
import { SignalEntity } from '../../interfaces/SignalEntity'
import { Card, Icon } from 'semantic-ui-react'
import { translateService } from '../../services/TranslateService';
import './SignalCard.scss';

interface SignalCardProps {
  signal: SignalEntity;
  faves: string[];
  filter: boolean;
  isFreeTrial: boolean;
  language: string;
  setFavourite: (signal: string, favourite: boolean) => void;
  onClick: () => void;
}

export const SignalCard = ({ signal, faves, filter, isFreeTrial, language, setFavourite, onClick }: SignalCardProps) => {
  const favourite = faves && !!faves.find((s: string) => s === signal.Symbol)

  if (filter && !isFreeTrial && !favourite) {
    return null;
  }

  return (
    <Card centered={true}>
      <Card.Content>
        {isFreeTrial ? null : <Icon className='float-right' size='large' name='star' color={favourite ? 'yellow' : 'grey'} onClick={() => setFavourite(signal.Symbol, !favourite)} />}
        <Icon className='float-left' size='large' name='calculator' color={'blue'} onClick={() => {
          onClick();
        }} />
        <Card.Header>{signal.Symbol}</Card.Header>
        <Card.Description><strong>{`${signal.Buy ? translateService.translate("signalCard.buy") : translateService.translate("signalCard.sell")} ${signal.Fast ? translateService.translate("signalCard.fast") : translateService.translate("signalCard.slow")}`}</strong> </Card.Description>
        {/* <Card.Description>{`${translateService.translate("signalCard.price")}: ${signal.OpenPrice}    ${translateService.translate("signalCard.stopLoss")}: ${signal.StopLoss}`}</Card.Description> */}
        {/* </Card.Content> */}
        {/* <Card.Content extra> */}
        {/* <Icon name='clock outline' />{`${new Date(signal.SignalDate).toLocaleString()}`} */}
        <Icon name='clock outline' />{`${signal.FirstOpenDate && signal.FirstOpenDate !== '' ? moment(signal.FirstOpenDate).locale(language).fromNow() : moment(signal.SignalDate).locale(language).fromNow()}`}
      </Card.Content>
    </Card>

  )
}
