import React from 'react'
import { Redirect } from 'react-router-dom';
import { Button } from 'semantic-ui-react'
import { translateService } from '../../services/TranslateService'
import './Subscribed.scss';

export const Subscribed = () => {
  const [redirect, setRedirect] = React.useState('');

  if (redirect !== '') {
    return <Redirect to={redirect} />
  }

  return (
    <div className='message-div'>
      <h2>{translateService.translate('subscribed.congrats')}</h2>
      <Button onClick={() => {
        setRedirect('/')
      }}>{translateService.translate('global.ok')}</Button>
    </div>
  )
}
