import React from 'react'
import { translateService } from '../../../services/TranslateService'
import { Button, Container } from 'semantic-ui-react'
import './InfoModal.scss';

interface IModalProps {
  message?: string;
  htmlMessage?: JSX.Element;
  onClose: () => void;
  onOK?: () => void;
  buttonText?: string;
}

export const InfoModal: React.FC<IModalProps> = ({ message, htmlMessage, buttonText, onClose, onOK }) => {
  const hMessage = htmlMessage || <p>{message}</p>;

  return (
    <div className='info-box'>
      {hMessage}
      <Button primary onClick={() => {
        onOK?.();
        onClose();
      }}>{translateService.translate(buttonText || 'global.ok')}</Button>
    </div>
  )
}
