import React, { FC, useEffect } from 'react';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import * as serviceWorker from './serviceWorker';
import { translateService } from './services/TranslateService';

const ServiceWorkerWrapper: FC = () => {
  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    toast({
      type: 'success',
      icon: 'info circle',
      title: '',
      description: translateService.translate('serviceWorker.newVersion'),
      animation: 'bounce',
      time: 60000,
    },
      () => null,
      () => reloadPage(registration.waiting),
      () => null
    );
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);



  const reloadPage = (r: ServiceWorker | null) => {
    r?.postMessage({ type: 'SKIP_WAITING' });

    window.location.reload(true);
  };

  return <SemanticToastContainer position='top-center' />

}

export default ServiceWorkerWrapper;