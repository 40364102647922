import React from 'react';
import { useHistory, Redirect } from "react-router-dom";
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import UserPool from '../../UserPool';
import generator from 'generate-password';
import { Container, Form, Button, Modal, Grid } from 'semantic-ui-react';
import { translateService } from '../../services/TranslateService';
import * as AnalyticsService from '../../services/AnalyticsService';
import { InfoModal } from '../shared/InfoModal/InfoModal';
import { version } from '../../../package.json';
import counterpart from 'counterpart';
import './Login.scss';
import { setupMaster } from 'cluster';

export const Login = () => {
  type phaseName = 'login' | 'enterCode' | 'changePassword' | 'signup';
  const [language, setLanguage] = React.useState(localStorage.getItem('lang') || 'en');
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');
  const [codeError, setCodeError] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [repeatPassword, setRepeatPassword] = React.useState('');
  const [oldPassword, setOldPassword] = React.useState('');
  const [code, setCode] = React.useState('');
  const [phase, setPhase] = React.useState('login' as phaseName);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [disclaimerModalOpen, setDisclaimerModalOpen] = React.useState(false);
  const [okFired, setOkFired] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [redirect, setRedirect] = React.useState('');
  const [user, setUser] = React.useState<CognitoUser>();
  const [authDetails, setAuthDetails] = React.useState<AuthenticationDetails>();

  const history = useHistory();

  React.useEffect(() => {
    const loggedInUser = UserPool.getCurrentUser();
    if (loggedInUser) {
      setRedirect('/');
    }
  }, []);

  React.useEffect(() => {
    if (okFired) {
      processLogin();
      setTimeout(() => setOkFired(false), 500)
    }
  }, [okFired]);

  const signout = () => {
    const loggedInUser = UserPool.getCurrentUser();
    if (loggedInUser) {
      loggedInUser.signOut()
      console.log('Logged out!');
    }
  }

  const newLanguage = (l: string) => {
    setLanguage(l);
    localStorage.setItem('lang', l)
  }

  counterpart.setLocale(language);


  const forgotPassword = () => {
    setModalMessage(translateService.translate(email === '' || emailError !== '' ? 'login.emailToSend' : 'login.sentDetails'));
    setModalOpen(true);
    if (email === '' || emailError !== '') {
      return;
    }
    setPassword('');
    setCode('');
    setRepeatPassword('');
    setPhase('enterCode');
    const forgottenUser = new CognitoUser({
      Username: email.toLowerCase().trim(),
      Pool: UserPool
    })
    if (forgottenUser) {
      forgottenUser.forgotPassword({
        onSuccess: () => null,
        onFailure: () => null
      })
    }
  }

  const resetPassword = () => {
    const forgottenUser = new CognitoUser({
      Username: email.toLowerCase().trim(),
      Pool: UserPool
    })
    if (forgottenUser) {
      forgottenUser.confirmPassword(code, password, {
        onSuccess: () => {
          console.log("onSubmit");
          AnalyticsService.logEvent('Reset Password Success', email.toLowerCase().trim().replace('@', ' '))
          history.push("/");
        },
        onFailure: err => {
          console.log("onFailure: ", err);
          AnalyticsService.logEvent('Reset Password Fail', email.toLowerCase().trim().replace('@', ' '))
          setModalMessage(translateService.translate('login.codeFailed'));
          setModalOpen(true);
        }
      })
    }
  }

  const onSignup = () => {
    setLoading(true);
    fetch('https://t9ub53rvrf.execute-api.us-east-2.amazonaws.com/default/signup',
      {
        method: 'POST',
        body: JSON.stringify({ email })
      })
      .then(data => data.json())
      .then(res => {
        console.log('res: ', res)
        setLoading(false);
        setModalOpen(true);
        if (res.message) {
          setModalMessage(translateService.translate('login.sentError') + res.message);
        } else {
          setModalMessage(translateService.translate('login.sentNew'));
          setPhase('login');
        }
      }).catch((err) => {
        console.log('err: ', err)
        setLoading(false);
        setModalMessage(translateService.translate('login.sentError') + JSON.stringify(err));
        setModalOpen(true);
      })
  };


  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const user = new CognitoUser({
      Username: email.toLowerCase().trim(),
      Pool: UserPool
    })

    const authDetails = new AuthenticationDetails({
      Username: email.toLowerCase().trim(),
      Password: phase === 'changePassword' ? oldPassword : password
    });

    setAuthDetails(authDetails);
    setUser(user);
    setDisclaimerModalOpen(true);

    //   cognitoUser.confirmRegistration('123456', true, function(err, result) {
    //     if (err) {
    //         alert(err.message || JSON.stringify(err));
    //         return;
    //     }
    //     console.log('call result: ' + result);
    // });
  }

  const processLogin = () => {
    if (!authDetails || !user) return;

    setLoading(true);

    user.authenticateUser(authDetails, {
      onSuccess: data => {
        setLoading(false);
        console.log('onSuccess: ', data);
        AnalyticsService.logEvent('Auth Password Success', email.toLowerCase().trim().replace('@', ' '));
        history.push("/");

        // user.getUserAttributes()
        // setExpiry(user, new Date().toISOString())
      },
      onFailure: err => {
        setLoading(false);
        console.log('onFailure: ', err);
        AnalyticsService.logEvent('Auth Password Fail', email.toLowerCase().trim().replace('@', ' '))
        setModalMessage(translateService.translate('login.invalid'));
        setModalOpen(true);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        AnalyticsService.logEvent('New Password Request', email.toLowerCase().trim().replace('@', ' '))
        setLoading(false);
        if (repeatPassword === '') {
          setOldPassword(password);
          setModalMessage(translateService.translate('login.change'));
          setModalOpen(true);
          setPassword('');
          setPhase('changePassword');
          return;
        }
        user.completeNewPasswordChallenge(repeatPassword, requiredAttributes, {
          onSuccess: data => {
            console.log('onSuccess: ', data)
            AnalyticsService.logEvent('New Password Success', email.toLowerCase().trim().replace('@', ' '));
            // are we logged in here?
            history.push("/");
          },
          onFailure: err => {
            console.log('onFailure: ', err);
            AnalyticsService.logEvent('New Password Fail', email.toLowerCase().trim().replace('@', ' '))
            setModalMessage(translateService.translate('login.failNew'));
            setModalOpen(true);
          },
        });

      }

    })

  }

  const loggedInUser = UserPool.getCurrentUser();
  if (loggedInUser) {
    console.log('Logged in!: ', loggedInUser);
    loggedInUser.getSession((err: any, session: any) => {
      if (err) {
        console.log('Session err: ', err);
        loggedInUser.signOut();
      } else {
        //.accessToken.jwtToken
        console.log('Got session: ', session)
      }
    })
  } else {
    console.log('Not logged in!')
  }

  // eslint-disable-next-line no-useless-escape
  const emailTestRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordTestRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!^%*?&])([a-zA-Z0-9@$!^%*?&.]{8,})$/;
  const codeTestRegex = /^[0-9]{6}$/;

  return (
    <Container textAlign='center'>
      {/* <p className="tiny-text">s/w ver: {version}</p> */}
      {redirect !== '' && <Redirect to={redirect} />}
      <Modal
        open={modalOpen}
        className='info-modal'
      >
        <InfoModal onClose={() => setModalOpen(false)} message={modalMessage} />
      </Modal>
      <Modal
        open={disclaimerModalOpen}
        className='info-modal'
      >
        <InfoModal onClose={() => setDisclaimerModalOpen(false)} htmlMessage={(
          <div>
            <p>{translateService.translate('disclaimer.text')}</p>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={translateService.translate('disclaimer.link')}
            >
              {translateService.translate('disclaimer.moreDetails')}
            </a><br /><br />
          </div>
        )}
          buttonText="disclaimer.button" onOK={() => {
            AnalyticsService.logEvent('Disclaimer Agreed', email.toLowerCase().trim().replace('@', ' '))
            setOkFired(true)
          }} />
      </Modal>
      <div className="selection-box">
        <Button.Group>
          <Button color={language === 'en' ? 'blue' : 'grey'} onClick={() => newLanguage('en')}>EN</Button>
          <Button color={language === 'fr' ? 'blue' : 'grey'} onClick={() => newLanguage('fr')}>FR</Button>
          <Button color={language === 'pt' ? 'blue' : 'grey'} onClick={() => newLanguage('pt')}>PT</Button>
          <Button color={language === 'it' ? 'blue' : 'grey'} onClick={() => newLanguage('it')}>IT</Button>
        </Button.Group>
      </div>
      <Grid centered>
        <Form>
          {(phase === 'login' || phase === 'signup') &&
            <div className="form-center">
              <Form.Input
                error={emailError === '' ? undefined : { content: emailError, pointing: 'below' }}
                label={translateService.translate('login.email')}
                placeholder={translateService.translate('login.emailPlaceholder')}
                id='form-input-first-name'
                value={email}
                onChange={(event: any) => {
                  setEmail(event.target.value);
                  setEmailError(emailTestRegex.test(event.target.value) ? '' : translateService.translate('login.validEmail'))
                }}
              />
            </div>
          }
          {phase === 'enterCode' &&
            <Form.Input
              error={codeError === '' ? undefined : { content: codeError, pointing: 'below' }}
              fluid
              label={translateService.translate('login.code')}
              placeholder={translateService.translate('login.codePlaceholder')}
              id='form-code'
              value={code}
              onChange={(event: any) => {
                setCode(event.target.value);
                setCodeError(codeTestRegex.test(event.target.value) ? '' : translateService.translate('login.validCode'))
              }}
            />}
          {phase === 'enterCode' || phase === 'changePassword' ?
            <React.Fragment>
              <Form.Input
                error={passwordError === '' ? undefined : { content: passwordError, pointing: 'below' }}
                fluid
                label={translateService.translate('login.newPassword')}
                placeholder={translateService.translate('login.choosePassword')}
                id='form-new-password'
                type='password'
                value={password}
                onChange={(event: any) => {
                  setPassword(event.target.value);
                  setPasswordError(passwordTestRegex.test(event.target.value) ? '' : translateService.translate('login.validPassword'))
                }}
              />
              <Form.Input
                fluid
                label={translateService.translate('login.confirmPassword')}
                placeholder={translateService.translate('login.confirmPasswordPlaceholder')}
                id='form-confirm-password'
                type='password'
                value={repeatPassword}
                onChange={(event: any) => {
                  setRepeatPassword(event.target.value);
                  setPasswordError(password === event.target.value ? '' : translateService.translate('login.samePassword'))
                }}
              />
            </React.Fragment>
            : phase !== 'signup' ?
              <Form.Input
                error={passwordError === '' ? undefined : { content: passwordError, pointing: 'below' }}
                fluid
                align="center"
                label={translateService.translate('login.password')}
                placeholder={translateService.translate('login.password')}
                id='form-input-password'
                type='password'
                value={password}
                onChange={(event: any) => {
                  setPassword(event.target.value);
                  setPasswordError(passwordTestRegex.test(event.target.value) || phase === 'login' ? '' : translateService.translate('login.validPassword'))
                }}
              /> : null}
          <Button
            type='submit'
            className={phase === 'signup' ? 'mtop' : ''}
            onClick={phase === 'enterCode' ? resetPassword : phase === 'signup' ? onSignup : onSubmit}
            primary={true}
            loading={loading}
            disabled={phase === 'signup' ? email === '' || emailError !== '' : email === '' || emailError !== '' || password === '' || passwordError !== '' || (phase === 'enterCode' && (repeatPassword === '' || codeError !== ''))}
          >{translateService.translate(phase === 'login' ? 'login.login' : phase === 'signup' ? 'login.signup' : 'login.setPassword')}</Button>
        </Form>
      </Grid>
      {
        phase === 'login' ?
          <a onClick={forgotPassword}>{translateService.translate('login.forgotPassword')}</a> :
          <a onClick={() => {
            setPhase('login');
            setPassword('');
          }}>{translateService.translate('global.back')}</a>
      }
      <br /><br />
      {
        phase === 'login' && <a onClick={() => {
          setPhase('signup');
          //setEmail('');
          setPassword('');
        }}>{translateService.translate('login.newSign')}</a>
      }
    </Container >
  )
}
