import React from 'react'
import { useStripe } from '@stripe/react-stripe-js';
import { Button } from 'semantic-ui-react';
import { translateService } from '../../services/TranslateService';
import * as AnalyticsService from '../../services/AnalyticsService';
import './CreditCard.scss';
import UserPool from '../../UserPool';

interface ICreditCardProps {
  clicked: () => void;
  language: string;
}

export const CreditCard: React.FC<ICreditCardProps> = ({ clicked, language }) => {
  const stripe = useStripe();

  return (
    <div>
      <Button className='buy-button' onClick={async () => {
        clicked();
        const loggedInUser = UserPool.getCurrentUser();
        if (!loggedInUser) {
          return;
        }
        loggedInUser.getSession(async (err: any, session: any) => {
          if (err) {
            console.log('Session err: ', err);
            return;
          } else {
            let country = 'FR';
            try {
              country = await fetch('https://api.country.is').then(res => res.json().then(c => c.country))
            } catch (e) { console.log(e) };

            AnalyticsService.logEvent('Clicked on Subscribe', loggedInUser.getUsername());
            fetch('https://t9ub53rvrf.execute-api.us-east-2.amazonaws.com/default/create-subs-session', {
              method: 'POST',
              headers: {
                Authorization: session.accessToken.jwtToken
              },
              body: JSON.stringify({ country, language })
            })
              .then(function (response) {
                return response.json();
              })
              .then(function (session) {
                return stripe?.redirectToCheckout({ sessionId: session.id });
              })
              .then(function (result) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, you should display the localized error message to your
                // customer using `error.message`.
                if (result?.error) {
                  alert(result.error.message);
                }
              })
              .catch(function (error) {
                console.error('Error:', error);
              });
          }
        })
      }}>
        {translateService.translate('subscribed.purchase')}
      </Button>
    </div >
  )
}
