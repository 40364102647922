import * as ReactGA from "react-ga";

interface AnalyticsObject {
  category: string;
  action: string;
  label: string; // reservation number
  // dimension1: string; // event uuid
  // dimension2: string; // epoch time
  // dimension3: string; // user id
  // dimension4: string; // first reservation for customer
  // dimension5: string; // PWA used
  value?: number;
}

// const DEVELOPMENT_ANALYTICS = false;

// export class analyticsService {
export const initialise = () => {
  // if (false) {
  //if (process.env.REACT_APP_CONTEXT === 'production') {
  // console.log("GA Initialise!");
  ReactGA.initialize("UA-121932567-2");
  //}
};

export const pageView = (url: string) => {
  // if (false) {
  //if (process.env.REACT_APP_CONTEXT === 'production') {
  // console.log("GA Pageview: ", url);
  ReactGA.pageview(url);
  //}
};

export const logEvent = (
  category: string,
  action: string,
  label: string = "",
  // altLabel?: string,
  value?: number
) => {
  const d = new Date();

  // let label = "-1";
  // let userId = "-1";
  // let firstTimeRes = "unknown";
  // const res = store.getState().chauffeurReducer.reservation;
  // if (res) {
  //   label = res.id.toString();
  //   firstTimeRes = res.identifyMethod === 0 ? "true" : "false";
  //   userId = res.customerId.toString();
  // } else {
  //   const user = store.getState().chauffeurReducer.user;
  //   if (user) {
  //     userId = user.id.toString();
  //   }
  // }

  const eventObject: AnalyticsObject = {
    category,
    action,
    // label: label + (error !== undefined ? " - " + error : ""),
    label,
    value
    // dimension1: uuid.v1(),
    // dimension2: d.getTime().toString(),
    // dimension3: userId,
    // dimension4: firstTimeRes,
    // dimension5: window.matchMedia('(display-mode: standalone)').matches === true ? 'true' : 'false'
  };

  // console.log("***Analytics Event*** ", eventObject);

  // if (
  // process.env.REACT_APP_CONTEXT !== "production" // &&
  // DEVELOPMENT_ANALYTICS === false
  //) {
  // if (!DEVELOPMENT_ANALYTICS) {
  // console.log("NON-PRODUCTION API: IGNORING!");
  //return;
  // }
  //}

  /*
  if (error && typeof error !== 'string') {
    error = '[non-string supplied as error]';
  }
  */

  // let res = store.chauffeurReducer.rootReducer.getState().map.reservation;

  // console.log("GA Event: ", eventObject);
  // ReactGA.set({
  // dimension1: eventObject.dimension1,
  // dimension2: eventObject.dimension2,
  // dimension3: eventObject.dimension3,
  // dimension4: eventObject.dimension4,
  // dimension5: eventObject.dimension5,
  // });
  ReactGA.event(eventObject);

  // console.log("Analytics Event: ", eventObject);
};
// }
