import React from 'react'
import { Container, Checkbox, Button, Dimmer, Loader, Icon, Modal } from 'semantic-ui-react';
import { SignalEntity } from '../../interfaces/SignalEntity';
import { SignalCard } from './SignalCard';
import counterpart from 'counterpart';
import { translateService } from '../../services/TranslateService';
import * as AnalyticsService from '../../services/AnalyticsService';
import UserPool from '../../UserPool';
import { Redirect } from 'react-router-dom';
import { DailyLevel } from '../../interfaces/DailyLevel';
import { version } from '../../../package.json';
import './Signals.scss';
import { Calculator } from '../Calculator/Calculator';
import { InfoModal } from '../shared/InfoModal/InfoModal';
import { CreditCard } from '../CreditCard/CreditCard';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeProdKey } from '../../credentials/stripe.json';
import { idText } from 'typescript';

export const Signals = () => {
  const [sort, setSort] = React.useState('SignalDate');
  const [signals, setSignals] = React.useState<SignalEntity[]>();
  const [levels, setLevels] = React.useState([] as DailyLevel[]);
  const [language, setLanguage] = React.useState('en');
  const [faves, setFaves] = React.useState([] as string[]);
  const [filter, setFilter] = React.useState(false);
  const [redirect, setRedirect] = React.useState('');
  const [currentSignal, setCurrentSignal] = React.useState<SignalEntity>();
  const [calcOpen, setCalcOpen] = React.useState(false);
  const [isFreeTrial, setIsFreeTrial] = React.useState(false);
  const [lastRefresh, setLastRefresh] = React.useState(0);
  const [showInfo, setShowInfo] = React.useState('');

  function changeFavesString(sym: string, val: boolean) {
    console.log(`${sym} to ${val}`)
    console.log("current array: ", faves);
    let newFavourites = [] as string[];
    if (val) {
      newFavourites = [...faves];
      if (!faves.find((s: string) => sym === s)) {
        newFavourites.push(sym)
      }
    }
    else {
      faves.forEach((s: string) => {
        if (s !== sym) {
          newFavourites.push(s)
        }
      });
    }
    console.log("New array: ", newFavourites);
    // localStorage.setItem('faves', JSON.stringify(newFavourites));
    if (!newFavourites.length) {
      setFilter(false)
    }
    setFaves([...newFavourites]);
    const loggedInUser = UserPool.getCurrentUser();
    if (!loggedInUser) {
      setRedirect('/login');
      return;
    }
    loggedInUser.getSession((err: any, session: any) => {
      if (err) {
        console.log('Session err: ', err);
      } else {
        AnalyticsService.logEvent('Set Favourites', loggedInUser.getUsername(), sym);
        fetch('https://t9ub53rvrf.execute-api.us-east-2.amazonaws.com/default/favourites',
          {
            method: 'POST',
            headers: {
              Authorization: session.accessToken.jwtToken
            },
            body: JSON.stringify({ favourites: newFavourites })
          })
      }
    })
  }

  const renderSignals = () => {
    // const order = sort === 'SignalDate' ? 1 : -1;
    // const sortBy = sort === 'SignalDate' ? 'SignalDate' : 'Symbol';
    if (!signals || signals.length < 1) {
      return (
        <React.Fragment>
          <p>{translateService.translate('sortOptions.subscribe')}</p>
          <Elements stripe={loadStripe(stripeProdKey)}>
            <CreditCard clicked={() => setSignals(undefined)} language={language} />
          </Elements>
        </React.Fragment>
      )
    }
    const sortedSignals = signals.sort((a: SignalEntity, b: SignalEntity) => {
      let aDate, bDate;
      a.FirstOpenDate && a.FirstOpenDate !== '' ? aDate = a.FirstOpenDate : aDate = a.SignalDate;
      b.FirstOpenDate && b.FirstOpenDate !== '' ? bDate = b.FirstOpenDate : bDate = b.SignalDate;

      return aDate > bDate ? -1 : 1;
      // a[sortBy] > b[sortBy] ? -order : order);
    })
    let returnJsx = [] as JSX.Element[];
    // if (signals[0].FreePeriod && signals[0].FreePeriod === true) {
    if (isFreeTrial) {
      returnJsx.push(<p className='tiny-text free-trial'>{translateService.translate('sortOptions.freeTrial')}</p>);
      returnJsx.push(<Elements stripe={loadStripe(stripeProdKey)}>
        <CreditCard clicked={() => setSignals(undefined)} language={language} />
      </Elements>);
    }

    sortedSignals.map(s => returnJsx.push(<SignalCard onClick={() => {
      setCurrentSignal(s);
      const l = levels.find(x => {
        // sometimes we have periods (.) on the end of some of these and not the other
        return x.Symbol.split('.')[0] === s.Symbol.split('.')[0];
      });
      if (!l || Math.abs(parseFloat(l.DailyCandle.Ask) - parseFloat(s.OpenPrice)) < Math.abs(parseFloat(s.OpenPrice) - parseFloat(s.StopLoss))) {
        setCalcOpen(true);
      } else {
        setShowInfo(translateService.translate('sortOptions.tooFar'));
      }
    }} key={s.TradeID} signal={s} faves={faves} filter={filter} language={language} setFavourite={changeFavesString} isFreeTrial={isFreeTrial} />))

    return returnJsx;
  }

  const newLanguage = (l: string) => {
    counterpart.setLocale(l);
    setLanguage(l);
    // localStorage.setItem('lang', l);
    const loggedInUser = UserPool.getCurrentUser();
    if (!loggedInUser) {
      setRedirect('/login');
      return;
    }
    loggedInUser.getSession((err: any, session: any) => {
      if (err) {
        console.log('Session err: ', err);
      } else {
        AnalyticsService.logEvent('Set Language', loggedInUser.getUsername(), l);
        fetch('https://t9ub53rvrf.execute-api.us-east-2.amazonaws.com/default/language',
          {
            method: 'POST',
            headers: {
              Authorization: session.accessToken.jwtToken
            },
            body: JSON.stringify({ language: l })
          })
      }
    })
  }

  const fetchSignals = () => {
    const nowTime = new Date().getTime();
    // fake the refresh if it happened within 20 seconds of the last one
    if (nowTime - lastRefresh < 20000) {
      setTimeout(() => setSignals(signals), 1000);
      setSignals(undefined);
      return;
    }
    setLastRefresh(nowTime);
    setSignals(undefined);

    const loggedInUser = UserPool.getCurrentUser();
    if (!loggedInUser) {
      setRedirect('/login');
      return;
    }

    loggedInUser.getSession((err: any, session: any) => {
      if (err) {
        console.log('Session err: ', err);
        setRedirect('/login');
        return;
      } else {
        console.log('Got session: ', session);
        fetch('https://t9ub53rvrf.execute-api.us-east-2.amazonaws.com/default/signals',
          {
            headers: {
              Authorization: session.accessToken.jwtToken
            }
          })
          .then(data => data.json())
          .then((jsonData) => {
            console.log(jsonData);
            if (jsonData.FreePeriod && jsonData.FreePeriod === true) {
              setIsFreeTrial(true);
            }
            setSignals(jsonData.Items);
          })
          .catch(err => {
            console.log('error: ', err);
            window.location.reload(true);
            //setSignals([])
          })
      }
    })
  }

  counterpart.setLocale(language);

  React.useEffect(() => {
    // localStorage.setItem('sort', sort)
  }, [sort]);

  React.useEffect(() => {
    const loggedInUser = UserPool.getCurrentUser();
    if (!loggedInUser) {
      setRedirect('/login');
      return;
    }

    loggedInUser.getSession((err: any, session: any) => {
      if (err) {
        console.log('Session err: ', err);
        setRedirect('/login');
        return;
      } else {
        console.log('Got session: ', session);
        AnalyticsService.logEvent('Got signals', loggedInUser.getUsername());
        fetchSignals();

        fetch('https://t9ub53rvrf.execute-api.us-east-2.amazonaws.com/default/favourites',
          {
            headers: {
              Authorization: session.accessToken.jwtToken
            }
          })
          .then(data => data.json())
          .then((jsonData) => setFaves([...jsonData.favourites]))
          .catch(err => console.log('error: ', err))

        fetch('https://t9ub53rvrf.execute-api.us-east-2.amazonaws.com/default/language',
          {
            headers: {
              Authorization: session.accessToken.jwtToken
            }
          })
          .then(data => data.json())
          .then((jsonData) => setLanguage(jsonData.language))
          .catch(err => console.log('error: ', err))

        fetch('https://t9ub53rvrf.execute-api.us-east-2.amazonaws.com/default/levels',
          {
            headers: {
              Authorization: session.accessToken.jwtToken
            }
          })
          .then(data => data.json())
          .then((jsonData) => setLevels(jsonData.Items))
          .catch(err => console.log('error: ', err))
      }
    })
  }, [])

  return (
    <Container textAlign='center' className='screen-top-margin'>
      {redirect !== '' && <Redirect to={redirect} />}
      <Calculator open={calcOpen} onClose={() => setCalcOpen(false)} signal={currentSignal} levels={levels} />
      <Modal
        open={showInfo !== ''}
        className='info-modal'
      >
        <InfoModal
          message={showInfo}
          onClose={() => setShowInfo('')} />
      </Modal>

      {faves.length && !isFreeTrial ?
        <div className="selection-box">
          <span className='text-small'>{translateService.translate('sortOptions.showAll')}&nbsp;&nbsp;&nbsp;</span><Checkbox defaultChecked={filter === true} slider onChange={(e, d) => d.checked ? setFilter(true) : setFilter(false)} /><span className='text-small'>&nbsp;&nbsp;&nbsp;{translateService.translate('sortOptions.showFavourites')}</span>
        </div> : null
      }

      <Button className='top-left' color='black' onClick={() => {
        UserPool.getCurrentUser()?.signOut();
        setRedirect('/login');
      }}>{translateService.translate('sortOptions.signOut')}</Button>

      <Icon className='float-right top-right' size='large' name='refresh' color={'blue'} onClick={() => fetchSignals()} />

      <div className="selection-box">
        <Button.Group>
          <Button color={language === 'en' ? 'blue' : 'grey'} onClick={() => newLanguage('en')}>EN</Button>
          <Button color={language === 'fr' ? 'blue' : 'grey'} onClick={() => newLanguage('fr')}>FR</Button>
          <Button color={language === 'pt' ? 'blue' : 'grey'} onClick={() => newLanguage('pt')}>PT</Button>
          <Button color={language === 'it' ? 'blue' : 'grey'} onClick={() => newLanguage('it')}>IT</Button>
        </Button.Group>
      </div>
      {
        !signals ? <Dimmer active>
          <Loader>Loading</Loader>
        </Dimmer> :
          renderSignals()
      }
      <hr />
      <p className="tiny-text">s/w ver: {version}</p>

    </Container >
  )
}
