import React from "react";
import { Modal, Grid, Form } from "semantic-ui-react";
import "./Calculator.scss";
import { SignalEntity } from "../../interfaces/SignalEntity";
import { DailyLevel } from "../../interfaces/DailyLevel";
import { translateService } from "../../services/TranslateService";

interface ICalculatorProps {
  open: boolean;
  onClose: () => void;
  signal: SignalEntity | undefined;
  levels: DailyLevel[];
}

// calculator
//double equityToRisk=(AccountBalance()+worstCaseProfit-profitToRemove)*RISK_NEW_TRADE;
//lots=equityToRisk*MarketInfo(symbol,MODE_TICKSIZE)/(MathAbs(openPrice-stopLoss))/(MarketInfo(symbol,MODE_TICKVALUE)*currencyFactor());
// round the lots to order depending on lot increments allowed
//lots=(floor(lots/MarketInfo(symbol,MODE_LOTSTEP)))*MarketInfo(symbol,MODE_LOTSTEP);

export const Calculator = ({
  open,
  onClose,
  signal,
  levels,
}: ICalculatorProps) => {
  // console.log("Symbol: ", signal?.Symbol);
  // console.log("Levels:", levels)
  const [balance, setBalance] = React.useState<number | undefined>(undefined);
  const [price, setPrice] = React.useState<number | undefined>(undefined);
  const [balanceError, setBalanceError] = React.useState('');
  const [priceError, setPriceError] = React.useState('');
  const [lotsToTrade, setLotsToTrade] = React.useState(-0.1);
  const [levelInfo, setLevelInfo] = React.useState<DailyLevel | undefined>();

  let renderText: JSX.Element | null = null;
  React.useEffect(() => {
    // balance && setBalance(0);
    price && setPrice(undefined);
  }, [open]);

  React.useEffect(() => {
    setLevelInfo(levels.find(l => {
      if (!signal) {
        return false;
      }
      // sometimes we have periods (.) on the end of some of these and not the other
      return l.Symbol.split('.')[0] === signal.Symbol.split('.')[0];
    }
    ))
  }, [signal]);

  React.useEffect(() => {
    if (!signal) {
      renderText = <div>Loading...</div>;
      return;
    }
    if (!levelInfo) {
      renderText = <div>Error, no calculator settings present!</div>;
      return;
    }
    const equityToRisk = (balance || 0) * 0.01;

    let tradeSize =
      (equityToRisk * parseFloat(levelInfo.SymbolParameters.TickSize)) /
      Math.abs(parseFloat(signal.OpenPrice) - parseFloat(signal.StopLoss)) /
      parseFloat(levelInfo.SymbolParameters.TickValue);
    tradeSize =
      Math.floor(tradeSize / parseFloat(levelInfo.SymbolParameters.LotStep)) *
      parseFloat(levelInfo.SymbolParameters.LotStep);
    setLotsToTrade(tradeSize);
    //renderText = lotsToTrade > 0 ? <div>Y'ALL MUST TRADE {lotsToTrade} LOTS ON A $10K ACCOUNT YO!</div> : <div>YOU TOO POOR SON!</div>
  }, [balance, levelInfo, signal]);

  if (renderText) {
    return renderText;
  }

  return (
    <Modal
      className="calc-modal"
      open={open}
      onClose={() => {
        // setBalance(0);
        onClose();
      }}
    >
      <Grid centered>
        <Form>
          <div className="form-center calc-div">
            <Form.Input
              error={
                balanceError === ""
                  ? undefined
                  : { content: balanceError, pointing: "above" }
              }
              label={translateService.translate("calculator.balance")}
              placeholder={translateService.translate(
                "calculator.balancePlaceholder"
              )}
              id="form-input-balance"
              value={balance}
              type="number"
              onChange={(event: any) => {
                setBalance(event.target.value);
                setBalanceError(
                  event.target.value.length > 0 && event.target.value > 0
                    ? ""
                    : translateService.translate("calculator.validBalance")
                );
              }}
            />
            <Form.Input
              error={
                priceError === ""
                  ? undefined
                  : { content: priceError, pointing: "above" }
              }
              label={translateService.translate("calculator.price")}
              placeholder={translateService.translate(
                "calculator.pricePlaceholder"
              )}
              id="form-input-price"
              value={price}
              type="number"
              onChange={(event: any) => {
                setPrice(event.target.value);
                setPriceError(
                  !signal || (event.target.value.length > 0 && Math.abs(event.target.value - parseFloat(signal.OpenPrice)) < Math.abs(parseFloat(signal.OpenPrice) - parseFloat(signal.StopLoss)))
                    ? ""
                    : translateService.translate("calculator.validPrice")
                );
              }}
            />
            {balanceError !== "" || !balance || priceError !== "" || !price
              ? <p className='error-text'>{translateService.translate("calculator.validAbove")}</p>
              : lotsToTrade === 0
                ? <p className='error-text'>{translateService.translate("calculator.tooLow")}</p>
                : <React.Fragment><p className='strong-text'>{signal && price && levelInfo ? `${signal.Buy ? translateService.translate("signalCard.buy") : translateService.translate("signalCard.sell")} ${lotsToTrade} ${signal.Symbol}, Stop Loss ${signal.Buy ? (price - Math.abs(parseFloat(signal.OpenPrice) - parseFloat(signal.StopLoss))).toFixed(parseInt(levelInfo.SymbolParameters.Digits)) : (price - (-1 * Math.abs(parseFloat(signal.OpenPrice) - parseFloat(signal.StopLoss)))).toFixed(parseInt(levelInfo.SymbolParameters.Digits))}` : null}</p>
                  <p className='error-text'>{translateService.translate("calculator.tighten")} {`${signal && signal.Buy ? translateService.translate("signalCard.buy") : translateService.translate("signalCard.sell")}`} {translateService.translate("calculator.tightenTwo")}</p> </React.Fragment>}
          </div>
        </Form>
      </Grid>
    </Modal>
  );
};
